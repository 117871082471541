.sign-in-form-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 12px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;

  .logo-app {
    width: 140px;
    height: 140px;
    margin-bottom: 30px;
    border-radius: 12px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .form-input-container {
      margin-bottom: 20px;
      position: relative;

      .form-input-label {
        font-size: 16px;
        color: #4b0082;
        position: absolute;
        top: -14px;
        left: 0;
        transition: all 0.2s ease-in-out;
        pointer-events: none;
        background-color: white;
        padding: 0 5px;
      }

      .form-input {
        width: 100%;
        height: 50px;
        border: 1px solid #4b0082;
        border-radius: 5px;
        padding: 10px;
        font-size: 16px;
        transition: all 0.2s ease-in-out;

        &:focus {
          border-color: #4b0082;
          outline: none;
        }
      }
    }

    .form-control {
      font-size: 14px;
      color: #4b0082;
      margin-bottom: 5px;
    }

    .checkbox {
      margin-left: 10px;
      transform: scale(1.5); /* Change the scale factor as desired */
    }

    .error-message-container {
      display: inline;
      color: #ff0000;
      font-size: 11px;
      margin-top: 0px;
      align-self: flex-end;
    }

    .sign-in-button {
      background-color: #4b0082;
      color: white;
      padding: 10px 20px;
      border-radius: 5px;
      border: none;
      margin-top: 20px;
      transition: all 0.2s ease-in-out;

      &:hover {
        cursor: pointer;
        transform: scale(1.1);
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .sign-in-form-container {
    width: 70%;
  }
}
