// .code-container {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   margin: 10%;
//   height: 100vh;

//   .code-scan-heading {
//     color: darkmagenta;
//   }

//   .logo-app {
//     margin-bottom: 1rem; /* add some margin to the bottom of the image */
//     height: 40%;
//   }
// }

.code-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f7f7f7;
}

.logo-app {
  height: 20%;
}

.code-scan-heading {
  font-size: 2rem;
  margin-top: 1rem;
  color: #555;
  text-align: center;
}
