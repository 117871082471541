.form-input-container {
  position: relative;

  .form-input-label {
    font-size: 16px;
    color: #4b0082;
    position: absolute;
    top: -14px;
    left: 0;
    transition: all 0.2s ease-in-out;
    pointer-events: none;
    background-color: white;
    padding: 0 5px;
  }

  .form-input {
    width: 100%;
    height: 50px;
    border: 1px solid #4b0082;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    transition: all 0.2s ease-in-out;

    &:focus {
      border-color: #4b0082;
      outline: none;
    }
  }
}
