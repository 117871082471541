.welcome {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem;
  height: 100vh; /* set the height of the container to the full viewport height */

  .event-heading {
    text-align: center;
    vertical-align: super;
    color: #555;
    // font-size: 3vw;
    font-size: 20px;

    font-weight: 400;
  }

  .event-picture {
    margin-bottom: 1rem; /* add some margin to the bottom of the image */
    height: 30%;
  }
}
